<template>
  <a-layout id="sims-layout">
    <a-layout-header :style="{ background: '#fff', padding: 0 }">
      <div class="left">
        <div class="menu-trigger">
          <a-icon @click.prevent="sidebarCollapsed = !sidebarCollapsed" type="menu"></a-icon>
        </div>
        <div class="sims-logo">
          <img src="@/assets/sims/logo-new.jpeg" height="35px" alt="" />
        </div>
        <!-- <a-button icon="copy"></a-button> -->
        <div class="topbar-breadcrumb">teamSOS Demo</div>
      </div>
      <div class="right">
        <!-- <a-input placeholder="Search...">
            <a-icon slot="prefix" type="search" />
          </a-input> -->
      </div>
      <div class="super-right">
        <!-- <div class="menu-item">
            <a-icon type="appstore" style="color: #C5CED2;"></a-icon>
          </div> -->
        <div class="menu-item">
          <a-avatar style="background-color: #0078D9">MB</a-avatar>
          <!-- <a-icon type="question-circle" style="color: #C5CED2;" /> -->
        </div>
      </div>
    </a-layout-header>


    <a-layout style="background: #F5F8FA;">
      <a-layout-sider :trigger="null" :collapsed="sidebarCollapsed" collapsible theme="light" :width="230"
        :collapsedWidth="0">

        <a-menu @click="handleMenuItemClicked" :defaultOpenKeys="['teamsos']" class="sims-sidebar-menu" theme="light"
          mode="inline">
          <a-menu-item key="1">
            <a-icon type="line-chart" />
            <span class="nav-text">Dashboard</span>
          </a-menu-item>
          <a-menu-item key="2">
            <a-icon type="team" />
            <span class="nav-text">Students</span>
          </a-menu-item>
          <a-menu-item key="3">
            <a-icon type="user" />
            <span class="nav-text">Staff</span>
          </a-menu-item>
          <a-menu-item key="4">
            <a-icon type="pie-chart" />
            <span class="nav-text">Reports</span>
          </a-menu-item>
          <a-sub-menu key="teamsos" class="teamsos-menu">
            <span slot="title">
              <a-icon type="lock" /><span>teamSOS</span>
            </span>
            <!-- <a-menu-item key="sims-lockdown">
                  <div class="tsos-menu-inner">
                      Call For Help
                  </div>
              </a-menu-item> -->
            <a-menu-item key="sims-announcements">
              <div class="tsos-menu-inner">
                Announcements
              </div>
            </a-menu-item>
            <a-menu-item key="sims-chats">
              <div class="tsos-menu-inner">
                Messaging
              </div>
            </a-menu-item>
            <a-menu-item key="sims-incidents">
              <div class="tsos-menu-inner">
                Incidents
              </div>
            </a-menu-item>
            <a-menu-item key="sims-guidance">
              <div class="tsos-menu-inner">
                Guidance
              </div>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="5">
            <a-icon type="check-square" />
            <span class="nav-text">Attendance</span>
          </a-menu-item>
          <a-menu-item key="6">
            <a-icon type="flag" />
            <span class="nav-text">Conduct</span>
          </a-menu-item>
          <a-menu-item key="7">
            <a-icon type="schedule" />
            <span class="nav-text">Exams</span>
          </a-menu-item>
          <a-menu-item key="8">
            <a-icon type="setting" />
            <span class="nav-text">Settings</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <slot />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      sidebarCollapsed: false,
      selectedKeys: ["tsos"],
    };
  },

  methods: {
    handleMenuItemClicked(e) {
      if (e.key == 'sims-lockdown') {
        this.$router.push('/sims/lockdown');
        return false;
      }
      if (e.key == 'sims-announcements') {
        this.$router.push('/sims/announcements');
        return false;
      }
      if (e.key == 'sims-chats') {
        this.$router.push('/sims/chats');
        return false;
      }
      if (e.key == 'sims-incidents') {
        this.$router.push('/sims/incidents');
        return false;
      }
      if (e.key == 'sims-guidance') {
        this.$router.push('/sims/guidance');
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
#sims-layout {
  .ant-layout-sider {
    // background: #3C444C;
  }

  .ant-layout-header {
    // border-bottom: 2px solid #F3F3F3;
    box-shadow: 0 0 3px rgb(0 0 0 / 20%) !important;
    z-index: 5000 !important;
    height: 50px;
    line-height: 50px;
    padding-left: 12px !important;

    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }

    .right {
      flex-shrink: 1;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      padding-right: 12px !important
    }

    .super-right {
      flex-shrink: 1;
      display: flex;
      height: 100%;
      padding-right: 15px;

      .menu-item {
        width: 45px;
        // border-left: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .topbar-breadcrumb {
      padding-left: 15px;
    }
  }

  .sims-sidebar-menu {
    height: 100%;
    // background: #3C444C;
    margin-top: 15px;
    border-right: 0 !important;

    .ant-menu-item-selected {
      background: transparent;
      background: #EAF8FF;
      font-weight: 400;
      &:after {
        border-right: 0 !important;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .ant-menu-sub {
      box-shadow: none;
      // background: #343C43 !important;
    }

    .teamsos-menu {
      padding-bottom: 13px;
      margin-bottom: 13px;
      // border-bottom: 2px solid #373F46;

      .ant-menu-item {
        // padding-top: 0;
        // padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 45px;
        line-height: 45px;
      }

      .tsos-menu-inner {
        // padding-top: 4px;
        // padding-bottom: 4px;
        // border-left: 1px solid #40474E;
        padding-left: 20px;
      }
    }
  }

  .ant-layout-sider-trigger {
    // background: #4C535C;
  }



  .sims-logo {
    // background: #fff;
    // // border-bottom: 2px solid #B0B3B6;
    // height: 50px;
    // box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    // clip-path: inset(-5px 0px -5px -5px);
    // display: flex;
    // align-items: center;
    // justify-items: center;
    // justify-content: flex-start;
    padding-left: 15px;
  }
}

#sims-layout {
  background: #f4f6fa;
  height: 100%;

  .form-inner {
    margin-top: 20px;
  }

  .form-last {
    margin-bottom: 10px;
  }

  .ant-btn-primary {
    background: #1976D2 !important;
    border-radius: 6px;

    &:disabled {
      opacity: 0.7;
    }
  }

  .card {
    // border: 1px solid #ebedef;
    border-radius: 10px;
    overflow: hidden;

    box-shadow: 0 0 4px rgb(0 0 0 / 0.04) !important;

    .nav {
      background: #2d353b;
      display: flex;

      .nav-item {
        color: #fff;
        padding: 15px;
        cursor: pointer;

        &.active {
          font-weight: 500;
        }
      }
    }

    .header {
      background: #D9F5F8;
      padding: 12px 18px;
      display: flex;
      justify-items: center;
      align-items: center;
      border-bottom: 2px solid #F9F9F9;

      h3 {
        // color: #fff;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .search {
      text-align: right;
      display: block;
    }

    .body {
      background: #fff;
      padding: 20px;

      &.bb {
        border-bottom: 2px solid #F9F9F9;
      }
    }
  }

  // .ant-layout-header {
  //   height: 50px;

  //   .logo {
  //     float: left;
  //     width: 180px;
  //     height: 50px;
  //     display: flex;
  //     align-items: center;

  //     img {
  //       height: 17px;
  //     }
  //   }

  //   .ant-menu {
  //     line-height: 50px !important;

  //     .ant-menu-item {
  //       color: #fff;

  //       &:hover,
  //       &.ant-menu-item-selected {
  //         background: #fff !important;
  //         color: #2d353b;
  //       }
  //     }
  //   }
  // }

  // .ant-layout-header {
  //   padding: 0 20px;
  // }

  .main-nav {
    z-index: 90;
  }

  .second-nav {
    background: #fff;
    // border-bottom: 1px solid #e3ebf6;
    display: flex;

    box-shadow: 0 3px 10px #888888;
    z-index: 50;

    .search {
      flex-shrink: 1;
      padding: 35px 0;
      background: #3497c6;
      width: 25px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .icon-wrapper {
      flex-grow: 1;
      display: flex;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;

      .icon {
        &.guidance {
          margin-left: 15px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-right: 15px;

        &:hover {
          cursor: pointer;

          .icon-inner {
            background: #eee;
          }
        }

        font-size: 12px;

        .icon-inner {
          border: 1px #2d353b solid;
          height: 40px;
          width: 40px;
          display: flex;
          align-content: center;
          justify-content: center;
          border-radius: 9px;
          align-items: center;
          justify-items: center;
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>